export const reservationStatus = [
  {
    id: -1,
    label: 'الكل',
  },
  {
    id: 1,
    label: 'قيد التنفيذ',
  },
  {
    id: 4,
    label: 'تم التنفيذ',
  },
  {
    id: 0,
    label: 'ملغي',
  },
];
